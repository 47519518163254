import React, { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Container,
  useMediaQuery,
  ButtonBase,
  Button,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { Link as RouterLink } from "react-router-dom";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "0px 8px 8px",
    margin: "0px 4px",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const MyTabScrollButton = forwardRef((props, ref) => {
  const { direction, ...other } = props;

  return (
    <ButtonBase
      component="div"
      ref={ref}
      {...other}
      disableRipple
      aria-label="Change Slide"
    >
      {direction === "left" ? (
        <i className="fa-solid fa-chevron-left" />
      ) : (
        <i className="fa-solid fa-chevron-right" />
      )}
    </ButtonBase>
  );
});

export default function BrandsSlider(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const slides = component.getData("default.slides", []);
  const value = component.getData("default.slide_index", false);

  const fix = useMediaQuery("(min-width:1415px)");

  return (
    <Container
      disableGutters
      maxWidth="lg"
      sx={{
        py: 2,
        px: { xs: 0, md: 1 },
      }}
    >
      <Typography
        component="h1"
        sx={{
          color: "#000",
          fontSize: { xs: 22, md: 28 },
          fontWeight: 500,
          textTransform: "none",
          lineHeight: 1,
          p: 0,
        }}
      >
        {component.trans("Browse by Brand")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "unset", lg: fix ? "center" : "unset" },
          my: 4,
        }}
      >
        {slides.length !== 0 && (
          <Tabs
            value={value}
            variant="scrollable"
            scrollButtons={!isMobile}
            ScrollButtonComponent={MyTabScrollButton}
            sx={{
              px: isMobile ? 0 : "30px",
              position: "relative",
              "& .MuiTabs-indicator": {
                // backgroundColor: "#6da545 !important",
                // height: "3px",
                display: "none",
              },
              "div:nth-of-type(1)": {
                left: "0px",
              },
              "div:nth-of-type(4)": {
                right: "0px",
              },
              "& .MuiTabs-scrollButtons": {
                color: "#000",
                backgroundColor: "#fff !important",
                height: "30px",
                width: "30px",
                zIndex: 100,
                top: "50%",
                webkitTransform: "translate(0,-50%)",
                transform: "translate(0,-50%)",
                position: "absolute",
                borderRadius: "50%",
              },
              "& .MuiTabs-scrollButtons.Mui-disabled": {
                opacity: 0.5,
              },
              "& .MuiSvgIcon-fontSizeSmall": {
                fontSize: "40px",
              },
            }}
          >
            {slides.map((slide) => {
              const { slug, name } = devHelper.getObjectValue(slide, "_values");
              return (
                <StyledTab
                  key={slug}
                  disableRipple
                  component={RouterLink}
                  to={`/products/brand/${slug}`}
                  sx={{
                    pb: 0,
                    mx: 0,
                    px: { xs: "4px" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    "& img": {
                      width: { xs: 160, sm: 200, md: 250 },
                    },
                  }}
                  label={
                    <img
                      src={`${filesUrl}/brands/images/portrait/${slug}.jpg?tr=w-250,v=${img_version}`}
                      alt={`${slug} logo`}
                      title={name}
                      loading="lazy"
                    />
                  }
                />
              );
            })}
          </Tabs>
        )}
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Button
          component={RouterLink}
          to="/brands"
          sx={{
            bgcolor: "transparent",
            fontSize: 14,
            color: "#313131",
            borderRadius: 30,
            border: "1px solid #313131",
            px: 3,
            height: "45px",
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
          disableRipple
        >
          {component.trans("view-all")}
        </Button>
      </Box>
    </Container>
  );
}
