import React from "react";
import { Box, Container } from "@mui/material";
import NavBar from "../../../Components/Widgets/Navbar";
import MenuDrawer from "../../../Components/Widgets/MenuDrawer";
import SubMenuDrawer from "../../../Components/Widgets/SubMenuDrawer";
import CartDrawer from "../../../Components/Widgets/CartDrawer";
import TopBannersSlider from "../../../Components/Widgets/Sliders/TopBannersSlider";
import Translate from "../../../../modules/Core/Components/Translate";
import Footer from "../../../Components/Widgets/Footer";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#F7F8FA",
      }}
    >
      <NavBar {...props} />
      <MenuDrawer {...props} />
      <SubMenuDrawer {...props} />
      <CartDrawer {...props} />
      <TopBannersSlider
        {...{
          ...props,
          ...{
            text_color: "#fff",
            top: "50%",
            left: "50%",
            center: "-50%,-50%",
            slides: [
              {
                slug: "slider-bazaar",
                url: "/bzr",
              },
            ],
          },
        }}
      />
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          mt: 2,
          px: 2,
          textAlign: "center",
          "& h5": {
            fontWeight: 400,
            lineHeight: 2,
            fontSize: { xs: 18, md: 20 },
          },
          "& a": {
            textTransform: "none",
            textDecoration: "none",
            color: "#333",
            "&:hover,&:focus": { color: "#333", opacity: 0.9 },
          },
          "& i": {
            mr: 1,
          },
        }}
      >
        <Translate
          entity="item"
          eslug="bazaar"
          scope="default"
          slug="default"
          auto
        />
        <div>
          <h5>
            <b>
              <a
                href="https://www.google.com/maps/place/%CE%9B%CE%B5%CF%89%CF%86.+%CE%94%CE%B7%CE%BC%CE%BF%CE%BA%CF%81%CE%B1%CF%84%CE%AF%CE%B1%CF%82+38,+%CE%97%CF%81%CE%AC%CE%BA%CE%BB%CE%B5%CE%B9%CE%BF+713+06/@35.3299181,25.1380395,17z/data=!4m5!3m4!1s0x149a5981031786ab:0xe9c7c58621a2688e!8m2!3d35.3299302!4d25.1378808"
                rel="noreferrer"
                target="_blank"
              >
                <i className="fa-solid fa-location-dot"></i>
                <Translate
                  entity="item"
                  eslug="store-6"
                  scope="default"
                  slug="address"
                  auto
                />
              </a>
            </b>
          </h5>
        </div>
        <div>
          <h5>
            <b>
              <a href="tel:2810346496">
                <i className="fa-solid fa-circle-phone"></i>
                2810346496
              </a>
            </b>
          </h5>
        </div>
        <div>
          <h5>
            <b>
              <a
                href="mailto:crete@casadipatsi.gr"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa-solid fa-circle-envelope"></i>
                crete@casadipatsi.gr
              </a>
            </b>
          </h5>
        </div>
      </Container>
      <Footer {...props} />
    </Box>
  );
};

export default ExportDefault;
