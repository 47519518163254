import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Fade,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Link,
  Typography,
  Container,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const BpIcon = styled("span")(({ theme }) => ({
  width: "7px",
  height: "7px",
  backgroundColor: "#fff",
  borderRadius: 50,
  opacity: 0.25,
  margin: "0 4px",
  "&:before": {
    display: "block",
    width: "12px",
    height: "12px",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    content: '""',
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  opacity: 1,
}));

function MyRadio(props) {
  return (
    <Radio
      sx={{
        px: "2px",
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default function MainBanner(props) {
  const { component } = props;

  const { slides = [] } = component.props;
  const { activeSlide = 0 } = component.getData("default", {});
  const filesUrl = component.getHelpers("env").get("new_files_url");

  const [timeout, setTimeoutt] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutt(3000);
    }, 100);
  }, []);

  const [imageSrc, setImageSrc] = useState("");

  const updateImageSrc = () => {
    const width = window.innerWidth;
    if (width <= 480) {
      setImageSrc(`-830-900.jpg?tr=w-480`);
    } else if (width <= 830) {
      setImageSrc(`-830-900.jpg?tr=w-830`);
    } else {
      setImageSrc(`-1300-600.jpg?tr=w-1300`);
    }
  };

  useEffect(() => {
    updateImageSrc(); // Set image initially based on current screen size
    window.addEventListener("resize", updateImageSrc);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateImageSrc);
  }, [slides]);

  const {
    top = null,
    bottom = null,
    left = null,
    right = null,
    center = null,
    text_color = "#fff",
    titleMaxWidthDesktop = null,
    titleMaxWidthMobile = null,
    textMaxWidthDesktop = null,
    textMaxWidthMobile = null,
  } = component.props;

  const text = (slide, f_color, view) => {
    const { slug } = slide;

    return (
      <Box
        sx={{
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          px: { xs: 2, lg: 0 },
          textAlign: "center",
        }}
      >
        {!component.trans(`${slug}-title`).includes("slider") && (
          <Typography
            component="h1"
            sx={{
              color: f_color,
              fontSize: { xs: 28, md: 40 },
              textTransform: "none",
              lineHeight: 1,
              textAlign: "center",
              p: 0,
              maxWidth: {
                xs: titleMaxWidthMobile ? titleMaxWidthMobile : "100%",
                lg: titleMaxWidthDesktop ? titleMaxWidthDesktop : "100%",
              },
              mx: "auto",
            }}
          >
            {component.trans(`${slug}-title`)}
          </Typography>
        )}
        {!component.trans(`${slug}-description`).includes("slider") && (
          <Typography
            component="h2"
            sx={{
              color: f_color,
              fontSize: { xs: 16, md: 22 },
              fontWeight: 350,
              textTransform: "none",
              textAlign: "center",
              my: 3,
              lineHeight: "22px",
              maxWidth: {
                xs: textMaxWidthMobile ? textMaxWidthMobile : "unset",
                lg: textMaxWidthDesktop ? textMaxWidthDesktop : "unset",
              },
              mx: "auto",
            }}
          >
            {component.trans(`${slug}-description`)}
          </Typography>
        )}
        {!component.trans(`${slug}-button`).includes("slider") && (
          <Typography
            component="h3"
            sx={{
              color: "#fff",
              fontWeight: 600,
              mt: 3,
              fontSize: { xs: 14, md: 16 },
              p: "8px 16px",
              maxWidth: "200px",
              bgcolor: "#000",
              mx: "auto",
              borderRadius: "8px",
              textTransform: "uppercase",
              "&:hover": {
                opacity: 0.9,
              },
            }}
          >
            {component.trans(`${slug}-button`)}
          </Typography>
        )}
      </Box>
    );
  };

  const arrows = {
    bgcolor: "transparent",
    position: "absolute",
    top: "50%",
    transform: "translate(0,-50%)",
    fontSize: 20,
    color: "#fff",
    p: 0,
    "&:hover": {
      bgcolor: "transparent",
    },
  };

  return (
    <Container
      maxWidth="lg"
      disableGutters
      sx={{
        position: "relative",
      }}
    >
      {slides.map((slide, index) => {
        const { slug, url } = slide;

        return (
          <Fade key={slug} in={activeSlide === index} timeout={timeout}>
            <Link component={RouterLink} to={url}>
              <img
                src={`${filesUrl}/marketing/sliders/main-slider/${slug}${imageSrc}`}
                alt={`${component.trans(slug)}`}
                style={{
                  width: "100%",
                  height: "auto",
                  position: index === 0 ? "relative" : "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                }}
                loading="lazy"
                width="1300"
                height="600"
              />
              <Box
                sx={{
                  position: "absolute",
                  top: top ? top : "unset",
                  bottom: bottom ? bottom : "unset",
                  left: left ? left : "unset",
                  right: right ? right : "unset",
                  transform: center ? `translate(${center})` : "unset",
                  width: "100%",
                }}
              >
                {text(slide, text_color, "desktop")}
              </Box>
            </Link>
          </Fade>
        );
      })}
      {slides.length > 1 && (
        <>
          <Button
            sx={{
              ...arrows,
              left: 0,
            }}
            disableRipple
            onClick={(e) => {
              component.startInterval(
                slides,
                activeSlide - 1 < 0 ? slides.length - 1 : activeSlide - 1
              );
            }}
          >
            <i className="fa-solid fa-chevron-left" />
          </Button>
          <Button
            sx={{
              ...arrows,
              right: 0,
            }}
            onClick={(e) => {
              component.startInterval(
                slides,
                slides.length <= activeSlide + 1 ? 0 : activeSlide + 1
              );
            }}
            disableRipple
          >
            <i className="fa-solid fa-chevron-right" />
          </Button>
          <Box
            sx={{
              backgroundColor: "transparent",
              position: "absolute",
              bottom: 30,
              left: 0,
              width: "100%",
              textAlign: "center",
            }}
          >
            <RadioGroup row value={activeSlide} style={{ display: "block" }}>
              {slides.map((slide, index) => (
                <FormControlLabel
                  key={"key-" + index}
                  value={index}
                  control={<MyRadio />}
                  onClick={(e) => {
                    component.startInterval(slides, index);
                  }}
                  id={"radio-" + index}
                  sx={{ mx: 0, "& .MuiButtonBase-root": { p: 0 } }}
                  aria-label={`slide ${index}`}
                />
              ))}
            </RadioGroup>
          </Box>
        </>
      )}
      {/* <Box
        sx={{
          textAlign: "center",
          color: "#000",
          fontWeight: 600,
          fontSize: 18,
          mt: 2,
          mb: 2,
        }}
      >
        {component.trans("closed-text")}
      </Box> */}
    </Container>
  );
}
